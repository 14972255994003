html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root:empty {
  height: 100%;
  position: relative;
}

#root:empty:before {
  content: "";
  pointer-events: none;
  border: 2px solid;
  border-top-color: #0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: .5s linear infinite AnimateLoading;
  position: absolute;
  top: calc(50% - 22px);
  left: calc(50% - 22px);
}

noscript {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  padding: 20px;
  display: block;
  position: absolute;
}

@keyframes AnimateLoading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.7afe1cca.css.map */
